import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./ModalMail.scss";
const ModalMail = ({closeModal}) => {
    const navigate = useNavigate(); 
    const { t } = useTranslation() 

    const handleClick = (type) => {
        closeModal(false)
        if (type === "redirect") {
            navigate('/')
        }
    }

    return (
        <div className="modal--background">
            <div className="modal--container">
            <button id="ModalClose" aria-label="Aria modal" title="close" className="modal--exit" onClick={() => handleClick("close")}>X</button>
            <div className="modal--texts">
                <h1 className="modal--title">{t('common.contact.modal.title')}</h1>
                <p className="modal--message">{t('common.contact.modal.message')}</p>
                <button  id="ModalBtn" aria-label="Aria modal" title="Homepage" className="modal--button" onClick={() => handleClick("redirect")}>{t('common.contact.modal.btn')}</button>
            </div>
            </div>
        </div>
    )
}
export default ModalMail;
