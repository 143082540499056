import React from 'react';
import { useEffect } from 'react'
import FooterNoCTA from '../../Components/FooterNoCTA/FooterNoCTA';
import MenuLight from '../../Components/MenuLight/MenuLight';
import './Policy.scss';
import './tablet.scss';

const Policy = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <MenuLight/>
    <main className="main--policy--container">
      <h2 className="policy--title">Privacy policies</h2>
      <p className="policy--text">As part of its commercial activity, CEERCLE PROJECT collects and processes personal data in order to provide information and services to its contributors and partners. The purpose of this Privacy policy is to govern the processing of personal data by CEERCLE PROJECT.</p>
      <div className="policy--section">
      <p className="policy--subtitle">Introduction</p>
      <p className="policy--text">CEERCLE PROJECT is committed to respecting the legislation and regulations in force concerning the protection of personal data, more specifically: </p>
      <ul className="policy--styled--list">
          <li>Regulation EU 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data (GDPR).</li>
          <li> The French Data Protection Act and Order No. 2018-1125 of 12 December 2018 on the protection of personal data amending Act 78-17 of 6 January 1978 on data processing, data files and individual liberties and various provisions concerning the protection of personal data.</li>
          <li>In accordance with the regulations in force, the data controller undertakes to process the personal data of its users in a fair, lawful and transparent manner for specific, explicit and legitimate purposes. </li>
      </ul>
      </div>
      <div className="policy--section">
      <p className="policy--subtitle">Article 1 - Definitions</p>
      <ul className="policy--unstyled--list">
          <li>Cookies : according to the GDPR and the e-Privacy directive, cookies are small text files that websites place on your device as you are browsing. They are processed and stored by your web browser. In and of themselves, cookies are harmless and serve crucial functions for websites. Cookies can also generally be easily viewed and deleted.</li>
          <li> Personal data : (hereinafter “Personal Data”), are defined according to Article 4 1§ of the GDPR as any information relating to an identified or identifiable natural person. </li>
          <li>Personal data : (hereinafter “Personal Data”), are defined according to Article 4 1§ of the GDPR as any information relating to an identified or identifiable natural person. </li>
          <li> Processing of personal data : (hereinafter “Processing”, is defined within the meaning of Article 4 §2 of the GDPR, as any operation or set of operations carried out using automated processes and applied to data or sets of data, such as collection, recording, storage, consultation and use.</li>
          <li>User : is designated, within the meaning of Article 4 1) of the GDPR, as a natural person who can be identified, directly or indirectly, in particular by reference to an identifier, such as a name, identification number or online identifier.</li>
          <li>Third-party sites : as per the GDPR, "third party" means a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorized to process personal data.</li>
      </ul>
      </div>

      <div className="policy--section">
      <p className="policy--subtitle">Article 2 - Data Controller</p>
      <p className="policy--text"> CEERCLE PROJECT acts as a data controller in the collecting and processing of personal data.</p>
      <p className="policy--text">The registered office of CEERCLE PROJECT, a simplified joint stock company with a capital of € 1,000, is located at 17 rue Gaston Dourdin - 93200 Saint-Denis - France. The company is registered in the R.C.S (Trade and Companies Register) of Bobigny under number 901 422 907, and is represented by its President Mr. Juan Carlos Gonzeles Camacho.</p>
      <p className="policy--text"> This website is subject to a declaration with the National Commission for Data Protection and Liberties (CNIL-France) under the Act N° 78-17 of 6 January 1978 On Information Technology, Data Files and Civil Liberties, as amended by the law of 6 August 2004. </p>
      <p className="policy--text">As a data controller, CEERCLE PROJECT ensures a high level of protection for the Personal Data of data subjects at all stages of processing (collection - storage - destruction …).</p>
      </div>

      <div className="policy--section">
      <p className="policy--subtitle">Article 3 - Categories of personal data collected</p>
      <p className="policy--text">During your interactions with CEERCLE PROJECT and as a User, you may be asked to provide information about yourself. In accordance with the principle of minimisation and privacy by default, only the data necessary to fulfil the following purposes are collected: </p>
      <p className="policy--subtitle">3.1 Data you provide directly: </p>
      <ul className="policy--styled--list">
          <li>Personal data, namely surname, first name, telephone number, email and postcode, in order to benefit from communications sent by CEERCLE PROJECT ; </li>
          <li> Professional data necessary to respond to a job offer, such as a resume, qualifications and employment history, may be collected ;  </li>
          <li>Any other information that you wish to bring to the attention of CEERCLE PROJECT.</li>
      </ul>
      <p className="policy--subtitle">3.2 Data we collect during our business relationship :  </p>
      <ul className="policy--styled--list">
          <li>Data relating to the personal area, namely the encrypted usernames and passwords allowing connection to the personal area ;</li>
          <li> Financial transaction data, within the framework of a payment and in order to perform contractual obligations, encrypted information relating to the payment method and data necessary for delivery may be collected ; </li>
          <li>Photographs of persons who agree to be published in the website ;</li>
          <li>Communication data, i.e. email address when subscribing to a newsletter.</li>
      </ul>
      <p className="policy--subtitle">3.3 Data we collect automatically :  </p>
      <ul className="policy--styled--list">
          <li>Browsing data, in particular cookies collected automatically, whether technical cookies necessary for browsing the website, statistical cookies or, on the basis of consent, advertising cookies or those necessary for redirection to our social networks.</li>
      </ul>
      </div>
      <div className="policy--section">
      <p className="policy--subtitle">Article 4 - Purpose of the collection of personal data</p>
      <p className="policy--text">In accordance with the principle of data minimisation established by Article 5 c) of the GDPR, only the data necessary to fulfil the previously established purposes are collected. Thus, processing of personal data is established for the following purposes :  </p>
      <ul className="policy--styled--list">
          <li>Subscription to the newsletter, monitoring of CEERCLE PROJECT’s latest news and information on the companies projects and development, on the basis of the User's consent (article 6 §1 a) of the GDPR) ;</li>
          <li> Creation of a personal area on the CEERCLE PROJECT platform (TIE) for the performance of contractual obligations (Article 6 §1 c) of the GDPR) ;</li>
          <li>Collection of cookies on the basis of consent (Article 6 §1 a) of the GDPR) ;</li>
          <li>Performance of agreements and online payment for the performance of contractual obligations (Article 6 §1 c) of the GDPR) ;</li>
      </ul>
      <p className="policy--text">Your participation is anonymous. But if you agree to it, you have the option of making your name and photograph public. These elements may be displayed on the website. You have the option of withdrawing your consent at any time through the personal area. </p>
      </div>

      <div className="policy--section">
      <p className="policy--subtitle">Article 5 - Duration</p>
      <p className="policy--text"> According to Article 5 d) of the GDPR, personal data collected and processed for the aforementioned purposes will be kept for a limited period of time that is strictly necessary for the fulfilment of the intended purposes. </p>
      <div className="policy--table">
  <div className="policy--table--header policy--tablet--bottomBorder policy--tablet--rightBorder">Data concerned</div>
  <div className="policy--table--header policy--tablet--bottomBorder policy--tablet--rightBorder">Purpose</div>
  <div className="policy--table--header policy--tablet--bottomBorder policy--tablet--rightBorder">Retention period</div>
  
    <div className="policy--table--rightBorder policy--tablet--bottomBorder">Identification data</div>
  <div>
      <p className="policy--table--rightBorder">Business and marketing information </p>
      <p className="policy--tablet--bottomBorder
      policy--table--rightBorder"> Operation of and access to the personal account on the site </p>
  </div>
  <div className="policy--tablet--bottomBorder">3 years from the last contact with the User</div>
  
    <div className="policy--tablet--bottomBorder
      policy--table--rightBorder">Professional data </div>
  <div className="policy--tablet--bottomBorder
      policy--table--rightBorder">Applying for a job offer</div>
  <div className="policy--tablet--bottomBorder">2 years from the last contact with the applicant</div>
  
      <div className="policy--tablet--bottomBorder
      policy--table--rightBorder">Financial transaction data and contact information</div>
  <div className="policy--tablet--bottomBorder
      policy--table--rightBorder">Fulfilment of contractual obligations and connection to the personal area
</div>
  <div className="policy--tablet--bottomBorder">Life of the contract plus the applicable limitation period 
</div>
  
  <div className="policy--tablet--bottomBorder
      policy--table--rightBorder">Browsing data </div>
  <div className="policy--tablet--bottomBorder
      policy--table--rightBorder">Collection of cookies when browsing the website </div>
  <div className="policy--tablet--bottomBorder">13 months from collection 
</div>
 </div>
      </div>

      <div className="policy--section">
      <p className="policy--subtitle">Article 6 - Measures taken for protection of personal data</p>
      <p className="policy--text"> CEERCLE PROJECT undertakes to take all technical and organisational measures to ensure the protection, security, integrity and confidentiality of Personal Data, in particular against any loss, alteration, distribution or illegal use.</p>
      <p className="policy--text">
 CEERCLE PROJECT ensures that such measures are in place for all operations carried out within the framework of its processing, and in particular during the collection, storage and hosting of Personal Data.
 </p>
      <p className="policy--text"> In this respect, CEERCLE PROJECT also ensures that the third-parties it may use (technical service providers, suppliers, etc.) respect this requirement to protect the User's Personal Data by implementing appropriate measures, in accordance with the GDPR. Technical and organisational measures implemented may include the use of secure registration forms, encryption and/or restricted access to Personal Data. </p>
      <p className="policy--text">  Certain messages or solicitations received may come from ill-intentioned persons seeking to obtain personal information about the User for fraudulent use (phishing). If the User receives a message that appears to be a phishing attempt, they are advised not to respond to it and not to open any attachments, images or links contained in the message.</p>
      </div>
      <div className="policy--section">
      <p className="policy--subtitle">Article 7 - Subcontractors
</p>
      <p className="policy--text"> Within the framework of its commercial activity, CEERCLE PROJECT may have to use subcontractors for whom it undertakes to maintain an adequate level of protection. </p>
      <p className="policy--text">
      Furthermore, all transfers of personal data to subcontractors located outside the European Union are regulated and provide all appropriate guarantees, in particular through the European Commission's standard contractual clauses which ensure an adequate level of protection and the protection of your fundamental rights. 
 </p>
      </div>
      <div className="policy--section">
      <p className="policy--subtitle">Article 8 - Third-Party site</p>
      <p className="policy--text"> In accordance with Article 7 of the present Privacy Policy, we accept no responsibility for third-party sites available through this Website, via hyperlink or otherwise. You are encouraged to review the terms of use applicable to those sites. Any access or use of a third-party site is solely at your own risk. </p>
      </div>
      <div className="policy--section">
      <p className="policy--subtitle">Article 9 - User’s Rights</p>
      <p className="policy--text">You have a right of access, rectification and opposition as well as erasure and portability when your data has been collected on the basis of consent and/or during the performance of a contractual obligation. </p>
      <p className="policy--text">To exercise these rights, you must send your request, proving your identity (surname, first name, username), to the following postal address : </p>
      <ul className="policy--styled--list">
          <li>CEERCLE PROJECT - 17 rue Gaston Dourdin - 93200 Saint-Denis - France</li>
          <li> email address: hello@ceercleproject.com</li>
      </ul>
      <p className="policy--text">In the event of a dispute, the data subject has the right to make a request directly to the competent supervisory authority (CNIL).  </p>
      </div>
      <div className="policy--section">
      <p className="policy--subtitle">Article 10 - Use of Cookies</p>
      <p className="policy--text">A cookie is a small computer file stored and read when viewing a website or mobile application, regardless of the type of terminal used (phone, computer, tablet, etc.).  </p>
      <p className="policy--text">Its purpose is to collect information relating to your browsing and to send you tailored content. Only the issuer of a cookie can read the information contained therein, for the period of its validity </p>
      <p className="policy--text">
When you visit our website, Google Analytics collects information in order to measure and analyse web traffic. Google Analytics only uses this information for statistical analysis purposes and then, after a certain time period, these data are deleted. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies should you prefer to do so. This may prevent you from taking full advantage of the website.
 </p>
 <p className="policy--text">The deposit of cookies other than purely technical ones is subject to obtaining your consent. In order to manage cookies as closely as possible to your wishes, we ask you to take into account the purpose of the cookies, specified below, when choosing your settings.</p>
      
      <p className="policy--subtitle">The website uses cookies for the following purposes :</p>
      <ul className="policy--styled--list">
          <li>Purely technical and necessary for the functioning of the site: These cookies allow the Site to function in an optimal and secure manner. They can also remember some of your choices, when you want them to ;</li>
          <li> Social Networks: To check if you are connected to third party services (Facebook, Twitter, Instagram, LinkedIn) ;</li>
          <li>Targeting: To allow us, retrospectively or in real time, to target (email campaigns, database enrichment) users who browse our Site ;</li>
          <li>Audience measurement: To track statistical data on traffic to the Site (i.e., how users use the Site and to improve the Site's services) and to help us measure and study the effectiveness of our online interactive content, features, advertisements and other communications ;</li>
      </ul>
      <p className="policy--text">Through these cookies, these third-parties may collect and use your browsing data for their own purposes, in accordance with their privacy policy.</p>
      <p className="policy--text">You can, at any time, withdraw your consent for the deposit of cookies during your browsing on our Site. </p>
      </div>
      <div className="policy--section">
      <p className="policy--subtitle">Article 11 - Passwords</p>
      <p className="policy--text">Certain Materials may be made available through a password protected area on this Website. </p>
      <p className="policy--text">Each password is intended to be confidential and used only by the individual or entity to whom it is issued. Do not disclose your password to any unauthorized person. </p>
      <p className="policy--text">
      If you access Materials through a password protected area, please note that the written agreements between you and the CEERCLE PROJECT, as well as any provisions set forth in those Materials, govern the parties’ respective rights and obligations with regard to those Materials. </p>
 <p className="policy--text">In particular, Materials provided through a password protected area generally are confidential and subject to limitations on disclosure and use, and  CEERCLE PROJECT generally have limited liability with respect to the accuracy and completeness of such Materials.</p>
      </div>
      <div className="policy--section">
      <p className="policy--subtitle">Article 12 - Modifications of these Terms</p>
      <p className="policy--text">CEERCLE PROJECT reserves the right to modify these Terms at any time. Please check these Terms periodically for changes. Your continued use of this Website after the posting of changes constitutes your binding acceptance of such changes. </p>
      </div>
     
    </main>
     <FooterNoCTA/>
     </div>
);
}
export default Policy;