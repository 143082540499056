import React, {useEffect} from 'react';
import './ImageRight.scss';
import './tablet.scss';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ImageRight = (props) => {
    const  {ref, inView} = useInView({
        threshold: .3,
        triggerOnce: true
    })
    const animation = useAnimation()

    useEffect(() => {
        if (inView) {
            animation.start({
                y : 0,
                opacity: 1,
                transition: {
                    type: "spring", duration: 2, bounce: .3
                }
            })
        }
        
        if (!inView) {
            animation.start({
                y : '50vh',
                opacity: 0,
                transition: {
                    type: "spring", duration: 2, bounce: .3
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inView])

    return (
        <div ref={ref}>
            <div className="benefits--container">
                <motion.div animate={animation} className="section--benefits--container--image"><img src={props.benefit} alt="Exclusive profiles" loading="lazy"></img></motion.div>
                <motion.div animate={animation} className="section--benefits--container--title">
                    <h3>{props.title}</h3>
                    <p> {props.content}</p>
                    <p className="benefits--freelancer">
                                    {props.freelancer1}

                    <span>{props.freelancer2} </span> {props.freelancer3}<span>{props.freelancer4}</span>  </p>
                </motion.div>
            </div>
        </div>
);
}
export default ImageRight;