import React from "react";
import { Link } from "react-router-dom";
import "./HeaderLeft.scss";
import "./tablet.scss";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";

const HeaderLeft = () => {
  const {t} = useTranslation();
  return (
    <header className="headerLeft--title--container">
      <motion.div className="headerLeft-title"
        initial={{opacity: 0}} 
        animate={{opacity: 1}} 
        transition={{duration: 2}}
      >
        <h2>
          {t("common.freelancers.header.title.part1")} 
          <span> {t("common.freelancers.header.title.part2")}</span> {t("common.freelancers.header.title.part3")}
        </h2>
        <p className="headerLeft--subtext">
        {t("common.freelancers.header.paragraph")}
        </p>
        <Link to="/contact" className="headerLeft--button">{t("common.freelancers.header.btn")}</Link>
      </motion.div>
    </header>
  );
};
export default HeaderLeft;
