import React, { useState } from "react"
import { useTranslation } from 'react-i18next';
import { Language } from "../../Language.js";
import detectBrowserLanguage from 'detect-browser-language';
import Select from "react-select";
// import { defaultStyles } from "react-select/dist/declarations/src/styles.js";

 
const Lang = (...props) => {
    const { i18n } = useTranslation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const flagsList = {
        fr:"🇫🇷 Fr",
        en:"🇺🇸 En"
    }
    const lsLang = localStorage.getItem("language")
    let browserLanguage = detectBrowserLanguage().split("-")
    let lingua = browserLanguage[1] !== undefined && (
        browserLanguage[1] === "EN" ||
        browserLanguage[1] === "FR") ?
        browserLanguage[1] : "EN"

    React.useEffect(() => {
        if (!lsLang) {
            localStorage.setItem("language", lingua)
        } else {
            console.log({i18n:i18n.language});
        }
    }, [i18n, lingua, lsLang])

    var nationality = [
        {
            id: "FR",
            lang: Language.FR,
            flag: "🇫🇷",
            text: "Fr",
            value: "fr",
            label: "🇫🇷 Fr"
        },
        {
            id: "EN",
            lang: Language.EN,
            flag: "🇺🇸",
            text: "En",
            value: "en",
            label: "🇺🇸 En"
        }
    ]



    // const sortDataBy = (nationality, byKey) => {
    //     let sortedData;
    //     if(byKey === 'id'){
    //     sortedData = nationality.sort(function(a,b,c){
    //         let x = a.id === lingua ? a.id : b.id;
    //         let y = b.id !== lingua ? b.id : a.id;
    //         if(x>y){return 1;}
    //         if(x<y){return -1;}
    //         return 0;
    //     });
    //     }
    //     return sortedData;
    // }
      
    const [lang, setLang] = useState(Language);
    const [flagCountry, setFlagCountry] = useState()
    React.useEffect(() => {

        setFlagCountry(flagsList[localStorage.getItem("i18nextLng")])
    },[flagsList])

    let changeLanguage = (event) => {
        console.log(lang);
        let language = event.value;
        localStorage.setItem("language", language)
        switch (language) {
            case Language.EN:
                setLang(Language.EN);
                i18n.changeLanguage(Language.EN);
                break;
            case Language.FR:
            default:
                setLang(Language.FR);
                i18n.changeLanguage(Language.FR);
                break;
        }
    }
    const customStyles = {
        option: (style, state) => ({
          ...style,
          color: state.isSelected ? "#FFF" : "#5257E5",
          backgroundColor: state.isSelected ? "#5257E5" : "transparent",
          cursor: "pointer"
        }),
    
        control: (style) => ({
          ...style,
          backgroundColor: "transparent",
          padding: "0px",
          border: "0",
          boxShadow: "none",
          cursor: "pointer" 
        }),
        singleValue: (style) => ({ ...style, color: "#FFF", cursor: "pointer" }),
        dropdownIndicator: (style) => ({ ...style, display:"none"}),
        indicatorsContainer: (style) => ({...style, display: "none"}),
        menu: (style) => ({...style, backgroundColor: "#FFF"})
        
      };
    
    return (
        <div>
            <div className="container-lang">
            <Select
                placeholder={flagCountry}
                onChange={changeLanguage}
                options={nationality}
                styles={customStyles}
                className="lang"
            />
            </div>
        </div>
    )
}
 
export default Lang;