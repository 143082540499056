import React, {useEffect} from 'react';
import './BenefitLeft.scss';
import './tablet.scss';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const BenefitLeft = (props) => {
    const  {ref, inView} = useInView({
        threshold: .3,
        triggerOnce: true
    })
    const animation = useAnimation()

    useEffect(() => {
        if (inView) {
            animation.start({
                y : 0,
                opacity: 1,
                transition: {
                    type: "spring", duration: 2, bounce: .3
                }
            })
        }
        
        if (!inView) {
            animation.start({
                y : '100vw',
                opacity: 0,
                transition: {
                    type: "spring", duration: 2, bounce: .3
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inView])
    return (
        <div ref={ref}>
            <div className="section--benefitsLeft--container">
                <motion.div animate={animation} className="section--benefitsLeft--container--title">
                    <p>
                        {props.title1}
                        <span>{props.title2}</span>
                        {props.title3}<span>
                        {props.title4}</span>
                    </p>
                </motion.div>
                <motion.div animate={animation} className="section--benefits--container--image"><img src={props.benefit} alt="Exclusive profiles" loading="lazy"></img></motion.div>
            </div>
        </div>
);
}
export default BenefitLeft;