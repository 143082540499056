import React from "react";
import { useTranslation } from 'react-i18next';

/* Styles*/
import "./Home.scss";
import "./small.scss";
import "./tablet.scss";
import "./lg.scss";
import "./xl.scss";
import "./2xl.scss";
/* Components*/
import Header from "../../Components/Header/Header";
import ImageRight from "../../Components/ImageRight/ImageRight";
import ImageLeft from "../../Components/ImageLeft/ImageLeft";
import Footer from "../../Components/Footer/Footer";
import Menu from "../../Components/Menu/Menu";

/* Images*/
import Benefits1 from "../../assets/easier1.webp";
import Benefits3 from "../../assets/easier2.webp";
import Benefits2 from "../../assets/easier3.webp";

function Home() {
    const {t} = useTranslation();

    return (
      <div>
        <Menu />
        <Header />
        <section className="section--benefit--container">
          <h2 className="hidden"> Looking for a tech profile has never been easier!</h2>
          <div>
            {/* <motion.div
              initial={{opacity: 0, y: 100}} 
              animate={{opacity: 1, y: 0}} 
              transition={{duration: 1.5}}
              ref={ref2}
            > */}
              <ImageRight
                benefit={Benefits1}
                title={t("common.home.element1.title")}
                content={
                  t("common.home.element1.text")
                }
              />
              <ImageLeft
                traduction={t}
                benefit={Benefits3}
                title={t("common.home.element2.title")}
                content={t("common.home.element2.text")}
              />
              <ImageRight
                benefit={Benefits2}
                title={t("common.home.element3.title")}
                content={
                  t("common.home.element3.text")
                }
              />
            {/* </motion.div> */}
          </div>
        </section>
        <Footer ctaH1={"Find your next rockstar"} cta={"Try for free"} landing={"/contact"}/>
      </div>
    );
}

export default Home;
