import React from 'react';
import { useEffect } from 'react'
/* Styles scss */
import "./Freelancer.scss";
import "./tablet.scss";
/* Components*/
import Menu from '../../Components/Menu/Menu';
import HeaderLeft from '../../Components/HeaderLeft/HeaderLeft';
import BenefitLeft from '../../Components/BenefitLeft/BenefitLeft';
import ImageRight from '../../Components/ImageRight/ImageRight';
import Footer from '../../Components/Footer/Footer';
/* Imagenes*/
import Benefits1 from '../../assets/freelancer_benefits1.webp';
import Benefits2 from '../../assets/freelancer_benefits2.webp';
import Benefits3 from '../../assets/freelancer_benefits3.webp';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';


function Freelancer() {
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
   <div>
     <Menu/>
     <HeaderLeft/>
     <section className="section--benefit--freelancer--container">      
        <motion.h2 
          className='section--freelancer--container--title'
          initial={{opacity: 0}} 
          animate={{opacity: 1}} 
          transition={{duration: 2}}
        > 
          {t("common.freelancers.content.title1")}
          <span>{t("common.freelancers.content.title2")}
          </span>{t("common.freelancers.content.title3")}
        </motion.h2>
     <BenefitLeft benefit={Benefits1} title1={t("common.freelancers.content.element1.text.part1")} title2={t("common.freelancers.content.element1.text.part2")} title3={t("common.freelancers.content.element1.text.part3")} title4={t("common.freelancers.content.element1.text.part4")}/>
     <ImageRight benefit={Benefits2} freelancer1={""} freelancer2={t("common.freelancers.content.element2.text.part1")}freelancer3={t("common.freelancers.content.element2.text.part2")}/>
     <BenefitLeft benefit={Benefits3} title1={t("common.freelancers.content.element3.text.part1")} title2={t("common.freelancers.content.element3.text.part2")} title3={t("common.freelancers.content.element3.text.part3")}/>
     </section>
     <Footer ctaH1={t("common.freelancers.content.cta.text")} cta={t("common.freelancers.content.cta.btn")} landing={"/contact"}/>
     </div>
  );
}

export default Freelancer;