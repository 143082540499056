import React from "react";
/* Styles*/
import "./Platform.scss";
import "./small.scss";
import "./tablet.scss";
import "./lg.scss";
import "./xl.scss";
import "./2xl.scss";
/* Components*/
import Header from "../../Components/Header/Header";
// import ImageRight from "../../Components/ImageRight/ImageRight";
// import ImageLeft from "../../Components/ImageLeft/ImageLeft";
import Footer from "../../Components/Footer/Footer";
import Menu from "../../Components/Menu/Menu";

/* Images*/
// import Benefits1 from "../../assets/easier1.webp";
// import Benefits3 from "../../assets/easier2.webp";
// import Benefits2 from "../../assets/easier3.webp";

function Platform(...props) {
    // window.scrollTo(0, 0);
  return (
    <div>
      <Menu />
      <Header />
      <section className="section--benefit--container">
        <h2> Looking for a tech profile has never been easier!</h2>
      </section>
      <Footer ctaH1={"Find your next rockstar"} cta={"Try for free"} landing={"/find"}/>
    </div>
  );
}

export default Platform;
