import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./MenuLight.scss";
import "./tablet.scss"
import Logo from "../../assets/tie_color.png";

const MenuLight = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuToggler = () => setMenuOpen((p) => !p);
  return (
    <div className="menuLight">
      <div className="menuLight__content">
        <div>
        <Link to="/">
            <img
              className="menu__logo"
              src={Logo}
              alt="Exclusive profiles"
            ></img>
        </Link>
        </div>

        <div>
          <nav
            className={ menuOpen ? "navLight__openLight" : "navLight"}
          >
            <Link to="/freelancer" className="navLight__itemLight">I'm freelancer</Link>
            <Link to="#" className="navLight__itemLight">Login</Link>
            <div className="navLight__button__container">
              <Button />
            </div>
          </nav>

          <button className="menuLight__toggler" onClick={menuToggler}>
            {!menuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default MenuLight;

const Button = () => {
  return <Link to="/contact" className="button">
  Contact us </Link>;
};
