import React from "react";
import { useEffect } from 'react'
import "./404.scss";
import "./tablet.scss";
import Menu from "../../Components/Menu/Menu";

const NotFounded = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Menu />
      <div className="main--notfound--container">
        <p className="notfound--subtitle">404</p>
        <h2 className="notfound--title"> Page not found</h2>
        <p className="notfound--text">
          Sorry, but the page you were looking for could not be found.
        </p>
        <a href="/" className="notfound--button">
          Back home
        </a>
      </div>
    </div>
  );
};
export default NotFounded;
