import React from 'react';
import { Link } from "react-router-dom";
import './Footer.scss';
import './tablet.scss'
import logo from '../../assets/tie_logo.png';

const Footer = (props) => {
    return (
    <footer className="">
        <div className="footer--cta--block">
            <p>{props.ctaH1}</p>
            <Link to={props.landing} className="footer--cta--button">{props.cta}</Link>
        </div>
        <div className="footer--container">
        <div className="footer--box footer--logo--container">
        <Link to="/" className="footer--cta--button">
            <img src={logo} alt="Tie logo, simboliza la union entre clientes y profesionales" />
        </Link>
        </div>
        <div className="footer--box footer--links--container">
            <ul>
                {/* <li><Link to="/about">About us</Link></li> */}
                <li><Link to="/">Tech It Easy Platform (TIE)</Link></li>
                <li><Link to="/freelancer">I'm freelancer</Link></li>
                <li><Link to="/contact">Contact us</Link></li>
            </ul>
        </div>
        <div className="footer--box footer--links--container">
            <ul>
            <li><Link to="/legal">Legal Terms</Link></li>
            <li><Link to="/policy">Privacy Policies</Link></li>
            </ul>
        </div>
        </div>
        <div className="footer--copyright">© 2021 – Ceercle Project</div>
    </footer>
);
}
export default Footer;