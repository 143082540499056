import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './i18n/en.json';
import translationFR from './i18n/fr.json';
import detectBrowserLanguage from 'detect-browser-language'


let browserLanguage = detectBrowserLanguage().split("-")
let lingua = browserLanguage[1] !== undefined && (
    browserLanguage[1] === "EN" ||
    browserLanguage[1] === "FR") ?
    browserLanguage[0] : "en"
let defaultLanguage = lingua;

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
};

i18n
.use(LanguageDetector)
.use(initReactI18next) // passes i18n down to react-i18next
.init({
    resources,
    lng: defaultLanguage,
    
    keySeparator: ".",  // to support nested translations
    
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

  export default i18n;