import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Header.scss";
import "./tablet.scss";
import "./lg.scss";
import "./xl.scss";
import "./2xl.scss";
import preview from "../../assets/home_header_dashboard.webp";
import { motion } from "framer-motion";

const Header = () => {
  const { t } = useTranslation();
  return (
    <header className="header--title--container">
      <motion.div className="header-title"
        initial={{opacity: 0}} 
        animate={{opacity: 1}} 
        transition={{duration: 2}}
      >
        <h1>
          {t('common.home.header.title1')}
          <span> {t('common.home.header.titlespecial')} </span> {t('common.home.header.title2')}
        </h1>
        <p className="header--subtext">
        {t('common.home.header.subtitle')}
        </p>
        <Link to="/contact" className="header--button">{t('common.home.header.btn')}</Link>
      </motion.div>
      <motion.picture
        initial={{opacity: 0}} 
        animate={{opacity: 1}} 
        transition={{duration: 2}}
      >
        <source media="(max-width: 768px)" srcSet={preview} />
        <img src={preview} alt="Chris standing up holding his daughter Elva" />
      </motion.picture>
    </header>
  );
};
export default Header;
