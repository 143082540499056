import React from 'react';
import { useEffect } from 'react'
import FooterNoCTA from '../../Components/FooterNoCTA/FooterNoCTA';
import MenuLight from '../../Components/MenuLight/MenuLight';
import './Legal.scss';
import './tablet.scss';

const Legal = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <MenuLight/>
    <main className="main--legal--container">

      <h2 className="legal--title">Legal terms</h2>
      <p className="legal--subtitle">www.tie.ceercleproject.com is published by</p>
      <p className="legal--text">Ceercle Project</p>

      <div className="legal--grid">
          <div className="legal--grid--container">
              <p className="legal--grid--title">Head Office </p>
              <p className="legal--grid--text">17 rue Gaston Dourdin 93200 Saint-Denis France</p>
          </div>
          <div className="legal--grid--container">
              <p className="legal--grid--title">Publication director </p>
              <p className="legal--grid--text"> Mathilde Sauziere</p>
          </div>
          <div className="legal--grid--container">
              <p className="legal--grid--title">Contact </p>
              <p className="legal--grid--text">hello@ceercleproject.com</p>
          </div>
          <div className="legal--grid--container">
              <p className="legal--grid--title">Trade and companies register number  </p>
              <p className="legal--grid--text">901 422 907</p>
          </div>
          <div className="legal--grid--container">
              <p className="legal--grid--title">SIRET number </p>
              <p className="legal--grid--text">901 422 907 00013</p>
          </div>
          <div className="legal--grid--container">
              <p className="legal--grid--title">VAT number </p>
              <p className="legal--grid--text">FR10901422907</p>
          </div>
          <div className="legal--grid--container">
              <p className="legal--grid--title">Website host:  </p>
              <p className="legal--grid--text">OVH SAS, a subsidiary of OVH Groupe SAS, a company registered in the Lille Trade and Companies Register under number 537 407 926 and located at 2, rue Kellermann, 59100 Roubaix.</p>
          </div>
      </div>
     
    </main>
    <FooterNoCTA/>
     </div>
);
}
export default Legal;