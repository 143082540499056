import React, { useState} from "react";
import "./Form.scss";
import { PopupButton } from "react-calendly";
import { useTranslation } from "react-i18next";
import ModalMail from "../ModalMail/ModalMail";
import {useForm} from 'react-hook-form';
import tools from "@jcgc212/tools";

const Form = () => {

  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation() 
  // eslint-disable-next-line no-unused-vars
  const {register,formState: {errors}, handleSubmit} = useForm();
  const onSubmit = (data) => {
    data.preventDefault()
    data.stopPropagation()

    
    
    let profile;
    for (const el of document.querySelectorAll('.custom-radio')) {
      if (el.childNodes[0].getAttribute('data-selected') === 'true') {
        profile = el.childNodes[0].id;
      }
    }

    let details = {
      name:data.target.name.value,
      email:data.target.email.value,
      type:profile,
      message:data.target.message.value,
      class:'general'
    }

    console.log(details);
    sendMail(details, data)
  
  };

  let sendMail = async (details, data) => {
    data.target.name.value = ""
    
    let response = await fetch(tools.pathAdapted(window.location.href,"sendit.ceercleproject.com","/api/v1/mail/tie"), {
    // let response = await fetch("//dev.sendit.ceercleproject.com/api/v1/mail/tie", {
        method: "POST",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details)
    }).catch((err) => {
        throw new Error(err);
    });
    let confimation = await response.json()
    if (confimation.status === "send") {
      setOpenModal(true)
      data.target.name.value = ""
      data.target.email.value = ""
      data.target.message.value = ""
    }
  }

  let selectOption = (e) => {
    for (let el of document.querySelectorAll('.custom-radio')) {
      el.querySelector('input').setAttribute('data-selected','false')
    }
    document.querySelector('#'+e.target.id).setAttribute('data-selected',"true")
    return e.target.id
  }
  return (
    <>
      <form className="form--contact" action="" method="post" onSubmit={onSubmit}>
        <h2 className="form--title--container">{t('common.contact.title')}</h2>
        <div className="form--input--container">
          <div className="form--input">
            <input type="text" placeholder={t('common.contact.name')} {...register('name', {required:true})} /> 
            {errors.name?.type === 'required' && <p className="input--error">{t('common.contact.messages.name')}</p>}
          </div>
          <div className="form--input">
            <input type="email" placeholder={t('common.contact.mail')} {...register('email', {required:true, pattern:/^[^\s@]+@[^\s@]+\.[^\s@]+$/i})} />
            {errors.email?.type === 'pattern' && <p className="input--error">{t('common.contact.messages.mail')}</p>}
          </div>
        </div>
        <div className="why-container">
          <h3 className="why-subtitle">{t('common.contact.profile')}</h3>
          <div className="why-grid">
            <label className="custom-radio">
              <input type="radio" name="professional" id="professional" value="professional" {...register('why')} data-selected = 'false' onClick={selectOption} />
              <span className="radio-btn">{t('common.contact.free')}
              </span>
            </label>
            <label className="custom-radio">
              <input type="radio" name="client" id="client" value="client" {...register('why')} data-selected = 'false' onClick={selectOption} />
              <span className="radio-btn">{t('common.contact.company')}
              </span>
            </label>
            <label className="custom-radio">
              <input type="radio" name="other" id="other" value="other" {...register('why')} data-selected = 'false' onClick={selectOption} />
              <span className="radio-btn">{t('common.contact.other')}
              </span>
            </label>
          </div>
        </div>

        <div className="msg">
          <div className="message">
            <label id="msg" htmlFor="message">
              {t('common.contact.TextMessage')}
            </label>
            <textarea name="message" id="message" rows="2" {...register('Message')}></textarea>
          </div>
        </div>
        <button id="formSubmit" aria-label="Aria submit" title="Send" type="submit"  className="form--button"> {t('common.contact.send')} </button>
        <div className="why-container" style={{marginTop:"10px", marginBottom:"-15px"}}>
          <h3 className="why-subtitle">{t('common.menu.meet')}</h3>
        </div>
        {/* <div className="form--button"> */}
              <PopupButton
                className="calendly-here"
                url="https://calendly.com/juancarlos-ceercleproject/tie"
                rootElement={document.getElementById("root")}
                text={t('common.contact.rdv')}
              />
            {/* </div>  */}
      </form>
      <div>
        {openModal && <ModalMail closeModal={setOpenModal}/>}
      </div>
    </>
  );
};

export default Form;
