import Home from "./Pages/Home/Home";
import Freelancer from "./Pages/Freelancer/Freelancer";
import NotFounded from "./Pages/404/404";
import Legal from "./Pages/Legal/Legal";
import Contact from "./Pages/Contact/Contact";
import Policy from "./Pages/Policy/Policy";
import Platform from "./Pages/Platform/Platform";
import './i18n';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="platform" element={<Platform />} />
      <Route path="freelancer" element={<Freelancer />} />
      <Route path="legal" element={<Legal/>} />
      <Route path="contact" element={<Contact/>} />
      {/* <Route path="find" element={<Find/>} /> */}
      {/* <Route path="about" element={<About/>} /> */}
      <Route path="policy" element={<Policy/>} />
      <Route path="*" element={<NotFounded />} />
    </Routes>
  </BrowserRouter>
  );
}