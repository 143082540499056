import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Menu.scss";
import "./tablet.scss";
import Logo from "../../assets/tie_logo.png";
import { PopupButton } from "react-calendly";
import Lang from "../Lang/Lang";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const Menu = () => {
  const { t } = useTranslation() 
  const [menuOpen, setMenuOpen] = useState(false);
  const menuToggler = () => setMenuOpen((p) => !p);

  const Button = () => {
    return <Link to="/contact" className="button">
            {t('common.menu.contact')} </Link>;
  };

  return (
    <motion.div className="menu"
      initial={{opacity: 0}} 
      animate={{opacity: 1}} 
      transition={{duration: 2}}
    >
      <div className="menu__content">
        <div>
        <Link to="/">
            <img
              className="menu__logo"
              src={Logo}
              alt="Exclusive profiles"
            ></img>
        </Link>
        </div>

        <div>
          <nav
            className={ menuOpen ? "nav__open" : "nav"}
          >
            <Link to="/freelancer" className="nav__item">{t('common.menu.freelancer')}</Link>
            <div className="nav__button__container calendly-btn">
              <PopupButton
                className="calendly-here"
                url="https://calendly.com/juancarlos-ceercleproject/tie"
                rootElement={document.getElementById("root")}
                text={t('common.menu.meet')}
              />
            </div>  
            <div className="nav__button__container">
              <Button />
            </div>
            <div className="nav__button__container lang">
              <Lang classTGive="nav__item"/>  
            </div>
          </nav>
          <button id="headerToogle" aria-label="Aria toogle" title="toogle" className="menu__toggler" onClick={menuToggler}>
            {!menuOpen ? (
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            ) : (
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            )}
          </button>      
        </div>
      </div>
    </motion.div>
  );
};
export default Menu;

