import React, {useEffect} from 'react';
import './ImageLeft.scss';
import './tablet.scss';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const ImageLeft = (props) => {

    const  {ref, inView} = useInView({
        threshold: .3,
        triggerOnce: true
    })
    const animation = useAnimation()

    useEffect(() => {
        if (inView) {
            animation.start({
                y : 0,
                opacity: 1,
                transition: {
                    type: "spring", duration: 2, bounce: .3
                }
            })
        }
        
        if (!inView) {
            animation.start({
                y : '50vw',
                opacity: 0,
                transition: {
                    type: "spring", duration: 2, bounce: .3
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inView])

    return (
        <div ref={ref}>
            <div className="section--benefits--container">
                <motion.div animate={animation} className="section--benefits--container--title">
                    <h3>{props.title}</h3>
                    <p> {props.content}</p>
                    <ul>
                        <li>{props.traduction('common.home.element2.bulletpoint1')}</li>
                        <li>{props.traduction('common.home.element2.bulletpoint2')}</li>
                        <li>{props.traduction('common.home.element2.bulletpoint3')}</li>
                        <li>{props.traduction('common.home.element2.bulletpoint4')}</li>
                    </ul>
                </motion.div>
                <motion.div animate={animation} className="section--benefits--container--image"><img src={props.benefit} alt="Exclusive profiles" loading="lazy"></img></motion.div>
            </div>
        </div>
);
}
export default ImageLeft;