import React from 'react';
import { useEffect } from 'react'
import Form from '../../Components/Form/Form';
import FooterNoCTA from '../../Components/FooterNoCTA/FooterNoCTA';
import Menu from '../../Components/Menu/Menu';

import './Contact.scss';
import './tablet.scss';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
    <div className="main--contact--container">
        <Menu/>
    <div className="main--contact--grid">
        <Form/>
    </div>
        <FooterNoCTA/>
    </div>
);
}
export default Contact;